@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

@layer base {
  html,
  body,
  #root {
    height: 100%;
  }
}

.overflowsss {
  overflow-y: hidden;
}

.react-responsive-modal-overlay {
  background: rgb(0 0 0 / 0%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

a:active {
  background-color: #43b7e5;
}

.lds-dual-ring {
  /* display: inline-block; */
  width: 30px;
  height: 30px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
